<template>
    <ion-page> </ion-page>
</template>



<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Help',
    setup() {
        
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


